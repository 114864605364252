$(document).ready(function () {

    $(function () {
        $(window).load(function() {

            var theWindow        = $(window),
                $bg              = $("#bg"),
                aspectRatio      = $bg.width() / $bg.height();

            function resizeBg() {

                if ( (theWindow.width() / theWindow.height()) < aspectRatio ) {
                    $bg
                        .removeClass()
                        .addClass('bgheight');
                } else {
                    $bg
                        .removeClass()
                        .addClass('bgwidth');
                }

            }

            theWindow.resize(resizeBg).trigger("resize");

        });
    });


    var $grid = $('.grid').imagesLoaded( function() {
        $('.grid').masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.grid-item',
            // use element for option
            columnWidth: '.grid-sizer',
            percentPosition: true,
            originLeft: false,
            resize:true
        });


    });
    var $gridProd = $('.grid-productions').imagesLoaded( function() {
        $('.grid-productions').masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.grid-item-prod',
            // use element for option
            columnWidth: '.grid-sizer-prod',
            percentPosition: true,
            originLeft: true,
            resize:true
        });
    });

    $('.extensions').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        adaptiveHeight: true,
        rtl: true,
        centerMode: true,
        prevArrow: '<button type="button" class="slick-prev">הבא</button>',
        nextArrow: '<button type="button" class="slick-next">הקודם</button>'


    });

    (function ($) {
        var _window = { w: window };

        function adjust_dfs(e) {
            var _fsx, _fsy, _fs, _adj, _n;

            // get the window dimensions
            _window.x = _window.w.innerWidth || _window.e.clientWidth || _window.g.clientWidth;
            _window.y = _window.w.innerHeight || _window.e.clientHeight || _window.g.clientHeight;

            // we get the "base font-size" by dividing into our "core" dimension on 1024x768 and multiplying
            // the result by 16 (initial font size for most
            var _fsx = (_window.x / 1024) * 12;
            var _fsy = (_window.y / 768) * 12;

            // if width > height, then we get the average font size from width and height calculations
            // otherwise, if the width of the window is less than the height, we use the width based size
            var _fs = (_window.x > _window.y ? ((_fsx + _fsy) * 0.5) : _fsx );

            // our minimum base font-size should be 8px, or whatever you want
            if (_fs < 8) {
                _fs = 8;
            }

            // our maximum base font-size should be 20px, or whatever you want
            if (_fs > 20) {
                _fs = 20;
            }


            // we bring the decimal point down to two places, so our performance doesn't take a hit
            // when trying to calculate text at a size of 8.1294129836928352903862391em
            _n = parseFloat(_fs, 10).toFixed(2);

            // setup the css definition object once
            _adj = {
                fontSize: _n + 'px'
            };

            // set the base font size onto our dfs class elements
            $('.dfs').css(_adj);

        }

        // run once on load
        adjust_dfs();

        // run on window resize
        $(window).on('resize', adjust_dfs);

    })(jQuery);

    $(".animsition").animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1500,
        outDuration: 800,
        linkElement: '.animsition-link',
        // e.g. linkElement: 'a:not([target="_blank"]):not([href^=#])'
        loading: true,
        loadingParentElement: 'body', //animsition wrapper element
        loadingClass: 'animsition-loading',
        loadingInner: '', // e.g '<img src="loading.svg" />'
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay : false,
        overlayClass : 'animsition-overlay-slide',
        overlayParentElement : 'body',
        transition: function(url){ window.location.href = url; }
    });
    //new Photostack( document.getElementById( 'photostack-3' ), {
    //    callback : function( item ) {
    //        //console.log(item)
    //    }
    //} );

    $('nav > li').click(function (e) {
        e.preventDefault();
        $('nav > li').removeClass('active');
        $(this).addClass('active');
    });
});